<template>
    <v-card flat class="cardBorderStyle">
        <div ref="chart"></div>
    </v-card>
</template>
<script>
import * as d3 from 'd3';
import { mapGetters } from 'vuex';
export default {
    name: 'dailyPieChart',
    data() {
        return {
            //
        }
    },
    computed: {
        ...mapGetters(['kioskAnalytics', 'pieChart', 'getPieChartTotal', 'kioskAnalyticsDimension', 'windowSize'])
    },
    watch: {
        kioskAnalytics(newVal) {
            this.createPieChart();
        },
        getPieChartTotal(newVal){
            this.createPieChart()
        },  
        windowSize(newVal) {
            this.createPieChart();
        }
    },
    methods: {
        createPieChart() {
            d3.select(this.$refs.chart).select('svg').remove();
            const width = this.kioskAnalyticsDimension.pieChartDimension.width;
            const height = this.kioskAnalyticsDimension.pieChartDimension.height;
            //const radius = Math.min(width, height) / 2.2;
            console.log('Pie chart values at pie chart vue page', this.pieChart)
            let pieChartVal = this.pieChart

            const svg = d3.select(this.$refs.chart)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [-width / 2, -height / 2, width, height])
                .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

            const color = d3.scaleOrdinal()
                .domain(pieChartVal.map(d => d.label))
                .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), pieChartVal.length).reverse())

            const arc = d3.arc()
                .outerRadius(Math.min(width, height) / 2 - 1)
                .innerRadius(0);

            const labelRadius = arc.outerRadius()() * 0.8

            const arcLabel = d3.arc()
                .innerRadius(labelRadius)
                .outerRadius(labelRadius);

            const pie = d3.pie()
                .value((d) => d.value);

            if (pieChartVal.every(item => item.value === 0)) {
                svg.append("text")
                    .attr("text-anchor", "middle")
                    .attr("dy", "0.3em")
                    .text("No data available");
            } else {
                pieChartVal.forEach((d) => {
                    d.percentage = (d.value / this.getPieChartTotal) * 100;
                });


                pieChartVal = pieChartVal.filter((d) => d.value > 0);



                const arcs = pie(pieChartVal)

                svg.append("g")
                    .attr("stroke", "white")
                    .selectAll()
                    .data(arcs)
                    .join("path")
                    .attr("fill", d => color(d.data.label))
                    .attr("d", arc)
                    .append("title")
                    .text(d => `${d.data.label}: ${d.data.value}}`);

                svg.append("g")
                    .attr("text-anchor", "middle")
                    .selectAll()
                    .data(arcs)
                    .join("text")
                    .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
                    .call(text => text.append("tspan")
                        .attr("y", "-0.4em")
                        .attr("font-weight", "bold")
                        .attr("font-size", '12px')
                        .text(d => d.data.label))
                    .call(text => text.filter(d => (d.endAngle - d.startAngle) > 0.25).append("tspan")
                        .attr("x", 0)
                        .attr("y", "0.7em")
                        .attr("font-weight", "bold")
                        .attr("fill-opacity", 0.7)
                        .text(d => `${d.data.value}`));
            }
            return svg.node();
        }
    }
}
</script>