<template>
  <v-container fluid>
    <v-card flat :height="headerCardHeight">
      <v-row no-gutters class="fill-height">
        <v-col cols="6" align-self="center">
          <v-row no-gutters>
            <v-col cols="6" >
              <v-card-title class="text-h4">
                <v-icon large left color="primary lighten-3">
                  mdi-google-analytics
                </v-icon>
                Kiosk Analytics
              </v-card-title>
              <v-chip color="primary" class="text-h6 mx-3 px-5">
                {{ getSelectedKiosk.data().name }}
              </v-chip>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="6">
          <v-row no-gutters class="fill-height mt-7">
            <v-col cols="5">
              <v-card width="85%">
                <v-card-title class="justify-center pa-2">
                    Total Interactions
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalUserSessions > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalUserSessions }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card width="85%" >
                <v-card-title class="justify-center pa-2">
                    Total Questions Asked
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalQuestionsAsked > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalQuestionsAsked }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="px-3 mt-3" flat>
      <v-tabs v-model="tab">
        <v-tab>
          <v-icon left>
            mdi-google-analytics
          </v-icon>
          Graphs</v-tab>
        <!-- <v-tab>
          <v-icon left>
            mdi-timer-check
          </v-icon>
          User Sessions</v-tab> -->
        <v-tab>
          <v-icon left>
            mdi-forum
          </v-icon>
          Q&A Analytics</v-tab>
        <v-spacer></v-spacer>
        <v-card flat width="200">
          <!-- <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2"
            @click="resetList()"></v-select> -->
          <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2" ref="durationSelector">
            <template v-slot:item="data">
              <v-list-item v-if="data.item === 'Select Date'"
                @click="$store.commit('setSingleDatePicker', true); selectedDuration = 'Select Date'">
                Select Date
              </v-list-item>
              <v-list-item v-else-if="data.item === 'Select Week'"
                @click="$store.commit('setWeekDatePicker', true); selectedDuration = 'Select Week'">
                Select Week
              </v-list-item>
              <v-list-item v-else @click="durationSetter(data.item)">{{ data.item }}</v-list-item>
            </template>
          </v-select>
        </v-card>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <reports :userSessionList="userSessionList" :selectedDuration="selectedDuration"></reports>
        </v-tab-item>
        <!-- <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item> -->
        <v-tab-item>
          <qnaAnalytics :analysisList="analysisList" :loading="loading">
          </qnaAnalytics>
        </v-tab-item>

        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import Reports from '../../components/dashboard/admin/kioskAnalytics/reports.vue';
import SingleDatePicker from "../../components/dialogBox/datePickers/singleDatePicker.vue";

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    Reports,
    SingleDatePicker
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5.5,
      duration: ['Today', 'Yesterday', 'This Week', '2024', 'Select Date', 'Select Week', 'Fiscal Year'],
      selectedDuration: 'Today'
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "getSelectedKiosk",
      "kioskAnalytics",
      "getWindowDimensions",
      "selectKioskAnalytics",
      "singleDatePicker"
    ]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getKioskName() {
      let temp;
      console.log('Selected Kiosk: ', this.allRegisteredKiosks.filter(kiosk => kiosk.id === this.selectKioskAnalytics))
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.id === this.selectKioskAnalytics)
      return temp[0].data().name
    },
    analysisList() {
      let interactions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        try {
          if (event.sessionData.requestType === 'Speech') {
            interactions.push({
              kioskId: event.browserKioskId,
              result: event.sessionData,
              timeStamp: event.sessionData.timeStamp,
              transcript: event.sessionData.action.trim(),
              id: event.id,
            });
          }
        } catch (err) {
          // console.error(err.message);
        }
      });
      return interactions.filter(interaction => interaction.kioskId === this.selectKioskAnalytics);
    },
    userSessionList() {
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        // console.log('What is in event in Anuall user session list : ', event)
        let startTime = moment(event.sessionStartTime);
        let endTime = moment(event.sessionEndTime);
        sessions.push({
          id: event.id,
          kioskId: event.browserKioskId,
          userSessionDate: event.sessionStartTime,
          userSessionDuration: endTime.diff(startTime),
          userSessionStartTime: event.sessionStartTime,
          userSessionEndTime: event.sessionEndTime,
          userSessionLogs: event.sessionData,
        });
      });
      return sessions.filter(session => session.kioskId === this.selectKioskAnalytics);
      //return sessions
    },
  },
  watch: {
    selectedKiosk(newVal) {
      this.loadData(newVal);
    },
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5.5;
    },
    selectedDuration(newVal) {
      if (newVal === 'Today') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else if (newVal === 'Yesterday') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Yesterday',
          start: null,
          end: null
        })
      }
      else if (newVal === 'This Week') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'This Week',
          start: null,
          end: null
        })
      } else if (newVal === '2024') {
        this.$store.dispatch('getKioskAnalytics', {
          type: '2024',
          start: null,
          end: null
        })
      } else if (newVal === 'Fiscal Year') {
        this.$store.commit("setFiscalYearPicker", true)
      } else if (newVal === null) {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      }
    }
  },
  methods: {
    durationSetter(timeframe) {
      this.selectedDuration = timeframe
      this.$refs.durationSelector.isMenuActive = false;
    }
  },
  mounted() {
    // if (this.allRegisteredKiosks.length === 0) {
    //   this.$store.dispatch("getAllRegisteredKiosks");
    // }
  },
};
</script>
<style scoped>
.slotLabelFont {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
}
</style>