<template>
    <v-card :height="kioskAnalyticsDimension.userSessionheight" flat>
        <v-row class="fill-height mt-15"
            v-if="getSelectedKiosk.arsBrowserId === 'ARS-D-ARS' || getSelectedKiosk.arsBrowserId === 'ARS-D-ARS2'">
            <v-col cols="5">
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === '2024'"></annual-bar-chart>
                <ThisWeekBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'This Week'"></ThisWeekBarChart>
                <ThisWeekBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Week'"></ThisWeekBarChart>
                <TodayBarChart class="mt-5" :userSessionList="userSessionList" v-if="selectedDuration === 'Today'">
                </TodayBarChart>
                <TodayBarChart class="mt-5" :userSessionList="userSessionList" v-if="selectedDuration === 'Yesterday'">
                </TodayBarChart>
                <TodayBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Date'">
                </TodayBarChart>
                <FiscalYearBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Fiscal Year'"></FiscalYearBarChart>
            </v-col>
            <!-- <v-col cols="4" v-if=""></v-col> -->
            <v-col cols="4" align="center">
                <pie-chart></pie-chart>
            </v-col>
            <v-col cols="3" align="center">
                <language-bar-chart></language-bar-chart>
            </v-col>
        </v-row>
        <v-row class="fill-height mt-15" v-else>
            <v-col cols="6">
                <annual-bar-chart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === '2024'"></annual-bar-chart>
                <ThisWeekBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'This Week'"></ThisWeekBarChart>
                <ThisWeekBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Week'"></ThisWeekBarChart>
                <TodayBarChart class="mt-5" :userSessionList="userSessionList" v-if="selectedDuration === 'Today'">
                </TodayBarChart>
                <TodayBarChart class="mt-5" :userSessionList="userSessionList" v-if="selectedDuration === 'Yesterday'">
                </TodayBarChart>
                <TodayBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Select Date'">
                </TodayBarChart>
                <FiscalYearBarChart class="mt-5" :userSessionList="userSessionList"
                    v-if="selectedDuration === 'Fiscal Year'"></FiscalYearBarChart>
            </v-col>
            <v-col cols="6" align="center" v-if="getSelectedKiosk.data().arsBrowserId === 'ARS-D-FORMS'">
                <PieChartEF></PieChartEF>
            </v-col>
            <v-col cols="6" align="center" v-else>
                <PieChartPO></PieChartPO>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import AnnualBarChart from '../../../graphs/annualBarChart.vue';
import { mapGetters } from 'vuex'
import PieChart from '../../../graphs/pieChart.vue';
import LanguageBarChart from '../../../graphs/languageBarChart.vue';
import ThisWeekBarChart from '../../../graphs/thisWeekBarChart.vue';
import TodayBarChart from '../../../graphs/todayBarChart.vue';
import FiscalYearBarChart from '../../../graphs/fiscalYearBarChart.vue';
import PieChartEF from '../../../graphs/pieChartEF.vue';
import PieChartPO from '../../../graphs/pieChartPO.vue';
export default {
    name: 'reports',
    props: {
        userSessionList: Array,
        selectedDuration: String,
    },
    computed: {
        ...mapGetters(['kioskAnalyticsDimension', 'userProfile', 'getSelectedKiosk'])
    },
    watch: {
        getSelectedKiosk(newVal) {
            console.log('New of selected kiosk: ', newVal)
        }
    },
    components: {
        AnnualBarChart,
        ThisWeekBarChart,
        TodayBarChart,
        PieChart,
        LanguageBarChart,
        FiscalYearBarChart,
        PieChartEF,
        PieChartPO
    },
    data() {
        return {
            //
        }
    },
    mounted() {
        console.log('getSelectedKiosk', this.getSelectedKiosk.data())
    }
}
</script>
<style>
.cardBorderStyle {
    border-right: solid 1px #111111 !important;
    border-radius: 0px !important;
}
</style>
